<template>
	<div class="fill-height">
		<div class="fill-height d-flex flex-column align-center justify-center" v-if="trackingNo">
			<v-icon light size="75" color="template-color darken-2">far fa-circle-check</v-icon>
			<h3 class="template-color--text text--darken-2 font-weight-bold text-center mt-10">
				Araç başarılı bir şekilde opsiyonlanmıştır. <br />Opsiyon süresi 48 saat sonunda dolacaktır.
			</h3>
			<div class="my-5 template-color--text text--darken-2">rezervasyon takip numaranız</div>
			<v-chip color="template-color" class="tracking-no">{{ trackingNo }}</v-chip>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReservationComplete',
	computed: {
		trackingNo() {
			return this.$route.query.onay;
		},
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.tracking-no {
	font-weight: bold !important;
	letter-spacing: 0.5em;
	padding-left: 1.3em;
	box-shadow: 0px 5px 5px #c9c9c9;
}
</style>
